import { mdiCheckBold, mdiExclamationThick, mdiPause, mdiPhone } from "@mdi/js";

export default {
  verified: {
    text: "Verified",
    iconComponent: "IconSolidCircleCheckmark",
  },
  active: {
    text: "Active",
    icon: mdiCheckBold,
    bgColor: "white",
    textColor: "#1FAE1F",
    iconCircleColor: "#1FAE1F",
    iconColor: "white",
  },
  trialEnded: {
    text: "Trial Ended",
    icon: mdiExclamationThick,
    bgColor: "white",
    iconColor: "white",
    textColor: "#ff000f",
    iconCircleColor: "#ff000f",
  },
  top: {
    text: "Top",
    iconComponent: "IconSolidCircleStar",
    bgColor: "#FF0000",
    iconColor: "#FF0000",
  },
  quality_mark: {
    text: "Quality Mark",
    iconComponent: "IconSolidCircleCrown",
    bgColor: "#FFB800",
    iconColor: "#FFB800",
  },
  reviewed_by_manager: {
    text: "Reviewed by manager",
    icon: mdiExclamationThick,
    bgColor: "white",
    iconColor: "white",
    textColor: "#333333",
    iconCircleColor: "#FFB800",
  },
  blocked_by_manager: {
    text: "Blocked by manager",
    icon: mdiExclamationThick,
    bgColor: "white",
    iconColor: "white",
    textColor: "#ff000f",
    iconCircleColor: "#ff000f",
  },
  paused: {
    text: "Paused",
    icon: mdiPause,
    bgColor: "white",
    iconColor: "white",
    textColor: "#ff000f",
    iconCircleColor: "#ff000f",
  },
  awaiting_call: {
    text: "Awaiting call",
    iconComponent: "IconSolidCircleHandset",
    bgColor: "#c208ac",
    iconColor: "white",
    textColor: "white",
  },
  pornstar: {
    text: "Pornstar",
    iconComponent: "IconSolidCircleMovie",
    bgColor: "#086ec2",
    iconColor: "white",
    textColor: "white",
  },
};
