
import ProfileTag from "@/components/v2/profile-tag.vue";
import statuses from "@/common/profile-statuses.js";

export default {
  components: {
    ProfileTag,
  },

  props: {
    profile: {
      type: Object,
      default: () => {},
    },

    direction: {
      type: String,
      default: "row", // row | col
    },

    radiusAlign: {
      type: String,
      default: null,
    },

    showText: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    displayStatuses() {
      const displayStatuses = [];

      if (this.profile?.profileStatus?.includes("verified"))
        displayStatuses.push(statuses.verified);

      if (this.profile?.tariff_id == 1) displayStatuses.push(statuses.top);

      if (this.profile?.profileStatus?.includes("quality mark"))
        displayStatuses.push(statuses.quality_mark);

      if (this.profile?.profileStatus?.includes("awaiting call"))
        displayStatuses.push(statuses.awaiting_call);

      if (this.profile?.pornstar == "yes")
        displayStatuses.push(statuses.pornstar);

      return displayStatuses;
    },
  },
};
