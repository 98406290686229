
import ProfileTags from "@/components/v2/profile-tags.vue";
import ProfileMenuV2 from "@/components/v2/profile-menu-v2.vue";

export default {
  components: {
    ProfileTags,
    ProfileMenuV2,
  },

  inject: { decodedDistrictId: { default: undefined } },

  props: {
    profile: {
      type: Object,
      required: true,
    },

    cardData: {
      type: Object,
      default: () => ({}),
    },

    bordered: {
      type: Boolean,
      default: false,
    },

    inFavorites: {
      type: Boolean,
      default: false,
    },

    isFavoriteProcessing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMenuOpen: false,
    };
  },

  methods: {
    processFavorite(profile) {
      this.$emit("favoriteClicked", profile);
    },
  },
};
