
import { mdiCheckBold } from "@mdi/js";
import { mapGetters } from "vuex";

const defaultStatus = {
  text: "Verified",
  icon: mdiCheckBold,
  textColor: "white",
  bgColor: "#1FAE1F",
  iconCircleColor: "#FFFFFF80",
  iconColor: "#1FAE1F",
  height: "21px",
};

export default {
  props: {
    status: {
      type: Object,
      default: () => {},
    },
    radiusAlign: {
      type: String,
      default: null,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      s: { ...defaultStatus, ...this.status },
    };
  },

  computed: {
    ...mapGetters({
      showTextFromStore: "global/getShowLegend",
    }),

    cssVars() {
      return {
        "--text-color": this.s.textColor,
        "--bg-color": this.s.bgColor,
        "--icon-circle-color": this.s.iconCircleColor,
        "--icon-color": this.s.iconColor,
        "--height": this.s.height,
      };
    },
  },
};
